<template>
  <ItemWrapper>
    <v-row>
      <v-col cols="12">
        <AppCard :loading="pending.getEditInvoice" :title="$t('Edit Invoice')" :prev-route="prevRoute">
          <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
            <v-form autocomplete="off" @submit.prevent="handleSubmit(submitForm)">
              <v-container py-0>
                <v-row wrap>
                  <v-col cols="12" sm="6" lg="4">
                    <VTextFieldWithValidation label="Client" disabled :value="client" />
                  </v-col>

                  <v-col cols="12" sm="3" lg="4">
                    <DatePickerWithValidation
                      v-model="billingdate"
                      label="Billingdate"
                      name="billingdate"
                      rules="required"
                      placeholder="Enter Billingdate"
                    />
                  </v-col>

                  <v-col cols="12" sm="3" lg="2">
                    <VTextFieldWithValidation
                      v-model="paymenttime"
                      label="Paymenttime"
                      name="paymenttime"
                      rules="required|integer|min_value:0"
                      placeholder="Enter Paymenttime"
                    />
                  </v-col>

                  <v-col cols="12" sm="4" lg="2">
                    <VTextFieldWithValidation
                      v-model="skonto"
                      label="Skonto"
                      name="skonto"
                      rules="integer|min_value:0"
                      placeholder="Enter Skonto"
                    />
                  </v-col>

                  <v-col cols="12" sm="4">
                    <VTextFieldWithValidation label="Tenant" :value="invoicePresets.tenants[tenant_id]" disabled />
                  </v-col>

                  <v-col cols="12" sm="4">
                    <VSelectWithValidation
                      v-model="invoicerange_id"
                      rules="required"
                      label="Invoicerange"
                      :items="invoiceRangesArray"
                      menu-props="offset-y"
                      placeholder="Select Invoicerange"
                    />
                  </v-col>

                  <v-col cols="12" sm="4" lg="2">
                    <VTextFieldWithValidation
                      v-model="discount"
                      label="Discount"
                      name="discount"
                      rules="integer|min_value:0"
                      placeholder="Enter Discount"
                    />
                  </v-col>

                  <v-col cols="12" sm="4" lg="2">
                    <VSelectWithValidation
                      v-model="discount_type"
                      label="Discount Type"
                      :items="discountTypes"
                      menu-props="offset-y"
                      placeholder="Select Discount Type"
                    />
                  </v-col>

                  <v-col cols="12" sm="4" lg="2">
                    <VSelectWithValidation
                      v-model="shipping_type"
                      label="Shipping Type"
                      :items="shippingTypes"
                      menu-props="offset-y"
                      placeholder="Select Shipping Type"
                    />
                  </v-col>

                  <v-col v-if="shipping_type" cols="12" sm="3" lg="4">
                    <DatePickerWithValidation
                      v-model="shippingdate"
                      label="Shipping Date"
                      name="shippingdate"
                      rules="required"
                      placeholder="Enter Shipping Date"
                    />
                  </v-col>

                  <v-col
                    v-if="shipping_type && shipping_type !== 'hide' && shipping_type.includes('period')"
                    cols="12"
                    sm="3"
                    lg="4"
                  >
                    <DatePickerWithValidation
                      v-model="shippingenddate"
                      label="Shipping End Date"
                      name="shippingenddate"
                      rules="required"
                      placeholder="Enter Shipping End Date"
                    />
                  </v-col>

                  <v-col cols="12" px-0>
                    <v-divider />
                  </v-col>

                  <v-col cols="12">
                    <h3 class="text-h5 mt-0">Positions</h3>

                    <draggable v-model="allProducts" handle=".handle">
                      <template v-for="(invoice_product, key) in invoice_products">
                        <InvoiceProductRow
                          :key="key"
                          :index="key"
                          :length="invoice_products.length"
                          :invoice-product="invoice_product"
                          @removeInvoiceProduct="removeInvoiceProduct"
                        />
                      </template>
                    </draggable>

                    <v-btn color="success" class="mt-4" @click="addInvoiceProduct">Add position</v-btn>
                  </v-col>

                  <v-col cols="12" px-0>
                    <v-divider />
                  </v-col>

                  <v-col cols="12">
                    <VTextareaWithValidation
                      v-model="extratext"
                      label="Invoice Text"
                      name="invoicetext"
                      placeholder="Enter Invoice Text"
                    />
                  </v-col>

                  <v-col cols="12">
                    <v-row justify="space-between">
                      <v-col order="2" order-sm="0" cols="12" sm="auto">
                        <v-btn
                          :loading="pending.getInvoicePdfPreview"
                          class="ml-0"
                          color="success"
                          outlined
                          :disabled="pending.editInvoice"
                          @click="previewInvoice"
                        >
                          Preview
                        </v-btn>
                      </v-col>
                      <v-col cols="12" sm="auto" class="text-sm-right">
                        <v-btn class="mr-2" color="success" :disabled="pending.editInvoice" @click="saveInvoice">
                          <v-icon class="d-sm-none">mdi-content-save</v-icon>
                          <span class="d-none d-sm-inline">Speichern</span>
                        </v-btn>

                        <v-btn type="submit" class="mr-0" color="success" :disabled="pending.editInvoice">
                          Bestätigen
                        </v-btn>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-container>
            </v-form>
          </ValidationObserver>
        </AppCard>
      </v-col>
    </v-row>

    <AppDialog :is-open="visiblePdf" max-width="800px" @onCloseDialog="visiblePdf = false">
      <template slot="title">View PDF</template>

      <v-responsive :aspect-ratio="210 / 297">
        <object :data="`data:application/pdf;base64,${invoicePdf}`" type="application/pdf" width="100%" height="100%" />
      </v-responsive>
    </AppDialog>
  </ItemWrapper>
</template>

<script>
import { computed, defineComponent, onUnmounted, ref } from '@vue/composition-api'
import { mapMultiRowFields } from 'vuex-map-fields'
import { mapFields } from 'vuex-composition-map-fields'
import { parseObjectToArray } from '@/helpers'
import { discountTypes, shippingTypes } from '@/config'

import useDirectRedirectToItem from '@/composables/useDirectRedirectToItem'

import draggable from 'vuedraggable'

import AppCard from '@/components/UI/AppCard.vue'
import DatePickerWithValidation from '@/components/inputs/DatePickerWithValidation.vue'
import InvoiceProductRow from '@/components/Invoices/InvoiceProductRow.vue'
import ItemWrapper from '@/layouts/ItemWrapper.vue'
import VSelectWithValidation from '@/components/inputs/VSelectWithValidation.vue'
import VTextareaWithValidation from '@/components/inputs/VTextareaWithValidation.vue'
import VTextFieldWithValidation from '@/components/inputs/VTextFieldWithValidation.vue'
import AppDialog from '@/components/UI/AppDialog.vue'

export default defineComponent({
  name: 'EditInvoice',
  components: {
    draggable,
    AppCard,
    DatePickerWithValidation,
    InvoiceProductRow,
    ItemWrapper,
    VSelectWithValidation,
    VTextareaWithValidation,
    VTextFieldWithValidation,
    AppDialog,
  },
  beforeRouteEnter(to, from, next) {
    next(vm => {
      // vm.prevRoute = from.fullPath === '/' ? vm.prevRoute : from.fullPath
    })
  },
  setup(props, { root: { $store, $router, $moment } }) {
    const { redirectDirect, setForceRedirect } = useDirectRedirectToItem()
    const prevRoute = ref('/Invoices')
    const visiblePdf = ref(false)
    const observer = ref(null)

    // store
    const pending = computed(_ => $store.state.invoice.pending)
    const invoice = computed(_ => $store.state.invoice.invoice)
    const invoicePresets = computed(_ => $store.state.invoice.invoicePresets)
    const invoicePdf = computed(_ => $store.state.invoice.invoicePdf)
    const errors = computed(_ => $store.state.invoice.errors)

    const getEditInvoice = _ => $store.dispatch('invoice/getEditInvoice')
    const editInvoice = onlyEdit => $store.dispatch('invoice/editInvoice', onlyEdit)
    const clearInvoice = _ => $store.dispatch('invoice/clearInvoice')
    const addInvoiceProduct = _ => $store.dispatch('invoice/addInvoiceProduct')
    const getInvoicePdfPreview = _ => $store.dispatch('invoice/getInvoicePdfPreview')
    const removeInvoiceProduct = productIndex => $store.dispatch('invoice/removeInvoiceProduct', productIndex)

    const updateAllInvoiceProducts = products => $store.commit('invoice/UPDATE_ALL_INVOICE_PRODUCTS', products)
    const addToast = toast => $store.commit('notifications/ADD_TOAST', toast)

    getEditInvoice()

    const invoiceRangesArray = computed(_ => {
      return parseObjectToArray(invoicePresets.value.invoiceranges)
    })

    const client = computed(_ => {
      let client = invoice.value.client.billing_handle.organisation
        ? `(${invoice.value.client.billing_handle.organisation}) `
        : ''
      return (client += `${invoice.value.client.billing_handle.firstname} ${invoice.value.client.billing_handle.lastname}`)
    })

    const allProducts = computed({
      set: val => updateAllInvoiceProducts(val),
      get: _ => invoice.value.invoice_products,
    })

    const saveInvoice = async _ => {
      if (pending.value.editInvoice) {
        return
      }

      const isValid = await observer.value.validate()
      if (isValid) {
        editInvoice(false).then(({ errors }) => {
          if (errors) {
            addToast({
              msg: 'Bearbeitung fehlgeschlagen',
              type: 'error',
            })
            return
          }

          addToast({
            msg: 'Rechnung erfolgreich bearbeitet',
            type: 'success',
          })
        })
      }
    }

    const previewInvoice = async _ => {
      if (pending.value.getInvoicePdfPreview) {
        return
      }

      const isValid = await observer.value.validate()
      if (isValid) {
        getInvoicePdfPreview().then(_ => (visiblePdf.value = true))
      }
    }

    const submitForm = _ => {
      if (pending.value.editInvoice) {
        return
      }
      if (redirectDirect.value) {
        setForceRedirect(true)
      }

      editInvoice().then(({ invoice, errors }) => {
        if (errors) {
          setForceRedirect(false)
          addToast({
            msg: 'Bearbeitung fehlgeschlagen',
            type: 'error',
          })
          return
        }

        addToast({
          msg: 'Rechnung erfolgreich bearbeitet',
          type: 'success',
        })

        if (redirectDirect.value) {
          $router.push({
            name: 'invoices-item',
            params: { id: invoice.id },
          })
        } else {
          $router.push({ path: prevRoute.value })
        }
      })
    }

    onUnmounted(_ => {
      clearInvoice()
    })

    return {
      prevRoute,
      client,
      observer,
      visiblePdf,
      invoicePdf,
      invoiceRangesArray,
      invoicePresets,
      allProducts,
      pending,
      errors,
      discountTypes,
      shippingTypes,
      submitForm,
      previewInvoice,
      addInvoiceProduct,
      removeInvoiceProduct,
      saveInvoice,
      ...mapFields('invoice', [
        'invoice.client_id',
        'invoice.billingdate',
        'invoice.paymenttime',
        'invoice.discount',
        'invoice.discount_type',
        'invoice.skonto',
        'invoice.tenant_id',
        'invoice.shipping_type',
        'invoice.shippingdate',
        'invoice.shippingenddate',
        'invoice.invoicerange_id',
        'invoice.extratext',
      ]),
    }
  },
  computed: {
    ...mapMultiRowFields('invoice', ['invoice.invoice_products']),
  },
}) //
</script>
